:root {
  --min-header-height: 640px;
  --container: 100vw;
  @screen 2xl {
    --container: 1590px;
  }
  // --ssr-padding-top: 0px !important;
}

#mainContent {
  min-height: 100vh; // bug chrome causa crash
  position: relative;
  padding-top: 0;
  transform-origin: top;

}

app.ssr #mainContent, app.ssr #mainContent.solid {
  padding-top: 64px !important;
}

@screen lg {
  app.ssr #mainContent {
    padding-top: 0px !important;
    
  } 
}
app.ssr #main-scrollbar .ng-scroll-content { // evita il layout shift sul contenuto della scrollbar, da verificare su mobile / safari
  padding-right: 5px;
}
app ng-scrollbar .scrollable-content { // evita il layout shift sul contenuto del virtual-scroller, da verificare su mobile / safari
  width: calc(100% - 5px);
}

#mainContent.solid {
  padding-top: 64px;
}

.ng-block .ng-scroll-content {
  display: block;
}

#main-scrollbar .scrollbar-control {
  z-index: 3;
}

#main-scrollbar.visible .scrollbar-control {
  opacity: 1;
}

#main-scrollbar {
  --vh: calc(var(--viewport-height) / 100);
}

app {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: 100%;

  #main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    min-width: 0;
  }
}
