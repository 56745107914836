toolbar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .mainCt {
    .mat-mdc-button.mat-unthemed {
      --mdc-text-button-label-text-color: @apply text-white #{!important};
    }
    transition: background-color 200ms linear;
    
  }

  &.solid {
    .mat-mdc-button.mat-unthemed {
      --mdc-text-button-label-text-color: @apply text-black #{!important};
    }
    
    height: 64px;
    .mainCt {
      height: 64px !important;
      position: relative !important;
      @apply bg-gray-100 #{!important};
    }

    #tb-logo {
      height: 55px !important;
    }

  }

  &.collapsed {
    .mainCt {
      height: 64px !important;
      position: absolute !important;
      color: #000 !important;
      @apply bg-gray-100 #{!important};
    }

    #tb-logo {
      transform-origin: center left;
      transform: scale(0.675);
    }
  }

  &.transparent, &.collapsed.transparent {
    .mainCt {
      position: unset !important;
      color: #000 !important;
      @apply bg-gray-100 #{!important};

      #tb-logo {
        height: 55px !important;
      }

      @screen lg {
        position: absolute !important;
        background-color: transparent !important;
        box-shadow: none !important;
        color: #fff !important;

        #tb-logo {
          height: 85px !important;
        }
      }
    }
  }

  #searchbox-wrap.searchbox-mobile-opened {
    transform: translateX(-266px);
    #search-box {
      visibility: visible;
    }
  }

  .action {
    position: relative;
    font-weight: 400 !important;
    color: inherit !important;
    margin-right: 30px;
    text-decoration: none;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: #dd1918;
      transition: all 200ms ease-out;
    }

    &:hover::after,
    &.is-active::after {
      width: 100%;
    }
  }

  .user-button,
  .language-button {
    min-width: 64px;
    height: 56x;
    font-size: 16px !important;
    font-weight: 500 !important;

    .action-button {
      min-width: 64px;
      height: 64px;
      font-size: 18px !important;
    }
  }
  
  #submenu{
    opacity: 0;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    background-color: #f5f5f5;
    color: #000;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
  }
  li {
    list-style:none
    }
  li:hover ul#submenu {
      opacity: 1;
      visibility: visible;
  }
  
}
