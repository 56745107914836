toolbar-menu {
  display: flex;

  .avatar-button {
    display: flex;
    align-items: center;

    // .avatar {
    //   //border: 2px solid white;
    // }

    .username {
      font-weight: 400;
    }
  }

  .mat-button {
    padding: 0 8px !important;
  }
}
